import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { authToken, baseUrl } from "../../../actions";
import { Editor } from "@tinymce/tinymce-react";
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import Re from "../../../assets/images/cat.svg";
import { Link } from "react-router-dom";
import Navbar from "../../../layouts/Navbar";
import Sidebar from "../../../layouts/Sidebar";


const role = localStorage.getItem("userRole");


const TermsAndConditions = props => {
    const { handleSubmit } = props;
    const [dataObj, setDataObj] = useState("");
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [dropdown, setDropdown] = useState({});

    const handleChange = (content) => {
        setDataObj(content);
    }

    const dropDownData = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        }
        axios.get(`${baseUrl}staff/templates`, requestOptions)
            .then((res) => {
                setDropdown(res.data?.data);
            }).catch((err) => {
                console.error(err);
            });
    }

    const changeHandler = (e) => {
        dropdown.map((item) => {
            if (item?.templateName === e.target.value) {
                setDataObj(item.termsAndConditions);
            }
        })
    }

    const submitHandler = (val) => {

        if (dataObj !== "" && val.templateId) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: String(dataObj)
            };
            let id;
            dropdown.map((item) => {
                if (item?.templateName === val.templateId) {
                    id = item.templateId;
                }
            });
            fetch(`${baseUrl}staff/templates/${id}/termsAndConditions`, requestOptions)
                .then(res => {
                    if (res.status >= 400) {
                        throw new Error("Server responds with error!")
                    }
                    return res.json()
                })
                .then(res => {
                    setData(res)
                },
                    err => {
                        setError(err)
                    })
        } else {
            toast("Please fill the Required fields");
        }
    }

    if (data.status === 200 && data.status !== undefined) {
        toast(data.data);
        data.status = undefined;
    } else if (data.status !== 200 && data.status !== undefined) {
        toast(data.data);
        data.status = undefined;
    } else {
        // console.log(data.status);
    }

    useEffect(() => {
        dropDownData();
        data.status = undefined;
    }, []);


    if (error) {
        return <div className="require"> {error.message} </div>
    } else {
        return (
            <>
                <div>
                    <Navbar />
                    <div className="dashboard-page">
                        <div className="dashboard-sidebar">
                            <Sidebar />
                        </div>
                        <div className="dashboard-main">
                            <Card className="staff-page">
                                <div className="page-head-split">
                                    <div className="head-colums">
                                        <div className="head-part-tab">
                                            <img src={Re} alt="..." className="head-imgs" />
                                            <h5>Settings</h5>
                                        </div>
                                        <div className="tab-links">
                                            <Link to={"/" + role + "/gst"}>GST</Link>
                                            <Link to={"/" + role + "/reference-id"} >Reference Id</Link>
                                            <Link to={"/" + role + "/unit"}>Unit </Link>
                                            <Link to={"/" + role + "/group"}>Quotation Group </Link>
                                            <Link to={"/" + role + "/content-of-letter"}>Content Of the Letter</Link>
                                            <Link to={"/" + role + "/terms-conditions"} className="active">Terms & Condition</Link>
                                        </div>
                                    </div>
                                </div>
                                <Row style={{ padding: "50px" }}>
                                    <Col md={9}>
                                        <form onSubmit={handleSubmit(submitHandler)}>
                                            <Field name="templateId" component="select" className="form-control form-select" onChange={changeHandler} >
                                                <option value="" disabled={true}>Select Template *</option>
                                                {dropdown.length > 0 ? dropdown?.map((item, i) =>
                                                    <option value={item?.templateName} key={i}>{item?.templateName}</option>) : null}
                                            </Field>
                                            <br></br>
                                            <Editor
                                                // apiKey="2j6wvm4x2pa25n680e9pbtskcfhyi2qdsvjut2c24rm83049"
                                                apiKey="t2uxglk9gljusugoe9lwfpa7bk6nxrc9ifde431f4sq1nkrv"
                                                value={dataObj}
                                                init={{
                                                    menubar: true,
                                                    plugins: 'lineheight print importcss tinydrive searchreplace autolink autosave save directionality  visualblock  link  charmap hr  insertdatetime  wordcount  textpattern noneditable ',
                                                    toolbar: 'lineheightselect',
                                                    lineheight_formats: "2pt 3pt 4pt 5pt 6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 32pt 36pt 38pt 40pt",
                                                    height: 350,
                                                }}
                                                onEditorChange={handleChange}
                                            />
                                            <div className="customer-sub qutation" style={{ marginTop: "30px" }}>
                                                <button type="submit" className="modal-btn">Save Changes</button>
                                            </div>

                                        </form>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loading: state.qutation.loading,
        quotationTemplates: state.qutation.quotationTemplates
    }
}
const Form = reduxForm({
    form: "ContentForm",
    destroyOnUnmount: false,
    enableReinitialize: true,
})(TermsAndConditions);

export default connect(mapStateToProps)(Form);





